export const DecimalFields = ["taxRate", "taxAmount"];

export class GridTaxData {
  rowNumber: number;
  description: { value: string, data: any };
  taxRate: { value: string, data: any };
  taxAmount: { value: string, data: any };

  constructor(rowNumber: any) {
    this.rowNumber = rowNumber;
    this.description = { value: '', data: {} };
    this.taxRate = { value: '', data: {} };
    this.taxAmount = { value: '', data: {} };
  }
}

export class TaskResponse {
  "type": string;
  "value": string|number;
  "owner": string;
  "page": number;
  "x": number;
  "y": number;
  "width": number;
  "height": number;
  "dpi": number;
}


export const toGridModel: any = {
  "TAX_TOTAL.TAX_SUBTOTAL.TAX_CATEGORY.NAME": "description",
  "TAX_TOTAL.TAX_SUBTOTAL.AMOUNT": "taxAmount",
  "TAX_TOTAL.TAX_SUBTOTAL.PERCENT": "taxRate",
}

export const fromGridModel: any = {
  "description": "TAX_TOTAL.TAX_SUBTOTAL.TAX_CATEGORY.NAME",
  "taxAmount": "TAX_TOTAL.TAX_SUBTOTAL.AMOUNT",
  "taxRate": "TAX_TOTAL.TAX_SUBTOTAL.PERCENT"
}
