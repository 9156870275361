import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { DateAdapter, ErrorStateMatcher } from '@angular/material/core';
import * as moment from 'moment';
import { BaseTranslationService } from '../app-services/base.translation.service';
import { LocalizationService } from '../app-services/localization.service';
import { SupplierGridComponent } from './dialog/supplier-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { map, startWith } from 'rxjs/operators';
import { ElementBase } from './element-base';
import { AppConfig } from '../app.config';
import countries from './../../assets/currency-list.json';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { HighlightSearchPipe } from '../pipes/HighlightSearchPipe';
import { AppUtils } from './app-util.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return (control! && (control.dirty && control.invalid));  // show error only when dirty and invalid
  }
}

@Component({
  selector: 'app-element',
  templateUrl: './dynamic-form-field.component.html',
  providers: [
    HighlightSearchPipe,
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'gt-single-select-overlay' },
    }
  ]
})
export class DynamicFormFieldComponent extends BaseTranslationService {

  constructor(localization: LocalizationService, public dialog: MatDialog, private _adapter: DateAdapter<any>) {
    super(localization);
  }

  readonly separatorKeys: number[] = [ENTER, COMMA];
  matcher = new MyErrorStateMatcher();
  @Input() element: ElementBase<any>;
  @Input() fieldList: ElementBase<any>[];
  @Input() flexVal: number;
  @Input() form: UntypedFormGroup;

  @ViewChild('trigger')
  autoComplete: MatAutocompleteTrigger;

  @Output() readFocusEvent = new EventEmitter<string>();
  @Output() clearClickEvent = new EventEmitter<string>();
  @Output() onDocTypeChange = new EventEmitter<string>();
  @Output() onChipSelect = new EventEmitter<string>();
  @Output() onChipRemove = new EventEmitter<string>();
  dateErrorMsg = '';
  formatPlaceholder: string;
  ibanPlaceholder: string;
  bbanPlaceholder: string;
  dateLocale: string;
  browserLocale: string;
  countries: any[];
  filteredCountries: Observable<any[]>;
  filteredCountriesArray: any[] = [];
  selectedCountry: any;
  lastFilter: string = '';
  movedWithTab = false;
  isBankInfoSuppliedViaGrid = false;
  isIbanInfoSuppliedViaGrid = false;

  ngOnInit() {
    this.browserLocale = navigator.language;
    this.toggleLocale(AppConfig.USER_CULTURE || this.browserLocale);
    if (this.element.fieldCode == 'CURRENCY') {
      this.setCountries();
    }
    if (this.element.fieldCode == 'IBAN_NUMBER' || this.element.fieldCode == 'BANK_ACCOUNT_VALUE') {
      this.element.value = JSON.parse(this.element.value);
      this.form.get(this.element.fieldCode)!.valueChanges.subscribe((value) => {
        this.element.value = JSON.parse(value);
      });
    }
    this.ibanPlaceholder = this.tr('spdf.app.field.placeholders.IBAN_NUMBER').toString();
    this.bbanPlaceholder = this.tr('spdf.app.field.placeholders.BANK_ACCOUNT_VALUE').toString();
    let panelBody = document.getElementsByClassName('mat-expansion-panel-body')[0] as HTMLDivElement;
    panelBody.addEventListener('scroll', this.scrollEvent, true);
  }

  scrollEvent = (event: any): void => {
    if (this.autoComplete?.panelOpen) {
      this.autoComplete.closePanel();
    }
  };

  toggleLocale(locale: string) {
    this._adapter.setLocale(locale);
    this.formatPlaceholder = moment.localeData(locale).longDateFormat('L');
  }

  toggleFormState(processingTask: boolean) {
    if (processingTask) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  validateForm() {
    return this.validateAllFormFields(this.form);
  }
  callParentReadFocus(event: any): void {
    this.readFocusEvent.next(event);
  }
  callParentClearClick(event: any): void {
    this.clearClickEvent.next(event);
  }

  clearGridSiblings(event: any): void {
    this.clearClickEvent.next(event);
    let supplierName: any = this.fieldList.find(f => { return f.fieldCode == 'SUPPLIER_NAME' });
    this.clearClickEvent.next(supplierName);
    if (this.isBankInfoSuppliedViaGrid) {
      let bankAccNo: any = this.fieldList.find(f => { return f.fieldCode == 'BANK_ACCOUNT_VALUE' });
      if (bankAccNo && this.form.get('BANK_ACCOUNT_VALUE')!.pristine) {
        this.clearClickEvent.next(bankAccNo);
      }
    }
    if (this.isIbanInfoSuppliedViaGrid) {
      let ibanNo: any = this.fieldList.find(f => { return f.fieldCode == 'IBAN_NUMBER' });
      if (ibanNo && this.form.get('IBAN_NUMBER')!.pristine) {
        this.clearClickEvent.next(ibanNo);
      }
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  getErrorMessage(element: any): string {
    const formControl: UntypedFormControl = (this.form.get(element.fieldCode) as UntypedFormControl);
    return formControl.hasError('matDatepickerParse') ?
      this.tr('spdf.app.module.bu.validation.field.incorrectFormat').toString() :
      (formControl.hasError('required') || formControl.hasError('whitespace')) ?
        this.tr('spdf.app.module.bu.validation.field.required').toString() :
        formControl.hasError('pattern') || formControl.hasError('decimalValidator') ?
          this.tr('spdf.app.module.bu.validation.field.incorrectFormat').toString() :
          formControl.hasError('requiredWarning') ? this.tr('spdf.app.module.bu.validation.field.required').toString() : 
          formControl.hasError('validationError') ? this.tr(formControl.getError('validationError')).toString() : '';
  }

  //err count is reqd for a special date case in which value is not 
  // updated so warning still remains and we have to ignore the warning and show yhe err in case of incorrect format
  getErrCount(errors: any) {
    return Object.keys(errors).length;
  }

  get errorRequiredWarning() {
    let errors = this.form.get(this.element.fieldCode)!.errors;
    return errors && errors['requiredWarning'] && Object.keys(errors).length == 1
  }

  get validationWarning() {
    let errors = this.form.get(this.element.fieldCode)!.errors;
    return errors && errors['validationError'] && Object.keys(errors).length == 1
  }

  resetDate(element: any) {
    this.form.get(element.fieldCode)!.setValue('');
  }

  onInputChange(element: any) {
    this.form.get(element.fieldCode)!.markAsTouched();
  }

  deepClear(event: any) {
    if (!event.value) {
      this.callParentClearClick(event);
      this.form.get(event.fieldCode)!.markAsDirty();
    } else {
      let isControlValid = !this.form.get(event.fieldCode)?.hasError('decimalValidator');
      if (isControlValid && event.fieldType == 'decimal') {
        event.value = AppUtils.localizeDecimal(event.value);
      }
    }
  }

  openSupplierGrid(element: any) {
    const dialogRef = this.dialog.open(SupplierGridComponent, {
      width: '1280px',
      maxHeight: '900px',
      data: { supplierCode: element.value }
    });
    dialogRef.afterClosed().subscribe((supplierData) => {
      if (supplierData) {
        this.form.markAsDirty();
        this.form.get('SUPPLIER_NAME')?.setValue(supplierData.supplierName);
        if (supplierData.bankAccount) {
          this.form.get('BANK_ACCOUNT_VALUE')?.markAsPristine();
          this.addChip({ value: supplierData.bankAccount }, [], 'BANK_ACCOUNT_VALUE');
          this.isBankInfoSuppliedViaGrid = true;
        }
        if (supplierData.iban) {
          this.form.get('IBAN_NUMBER')?.markAsPristine();
          this.addChip({ value: supplierData.iban }, [], 'IBAN_NUMBER');
          this.isIbanInfoSuppliedViaGrid = true;
        }
        element.value = supplierData.supplierCode.toString();
      }
    });
  }

  showClearButton(): boolean {
    if (typeof this.formControl.value === 'string') {
      return this.formControl.value != '' && this.formControl.value.trim() !== '';
    } else {
      return this.formControl.value;
    }
  }

  // Clear search string or search string AND selected item
  clearField(panel: MatAutocomplete) {
    if (panel.isOpen) {
      this.formControl.setValue('');
    } else {
      this.formControl.setValue('');
      this.selectedCountry = null;
    }
  }

  onPanelClosed() {
    if (this.selectedCountry) {
      this.formControl.setValue(this.selectedCountry);
    } else if (!this.movedWithTab) {
      this.formControl.setValue('');
    }
  }

  onPanelOpened() {
    if (!this.movedWithTab) {
      this.formControl.setValue('');
    } else {
      this.movedWithTab = false;
    }
  }

  // Store the user's selection & update form control
  onSelectionUpdate(event: MatAutocompleteSelectedEvent) {
    this.selectedCountry = event.option.value;
    this.formControl.setValue(this.selectedCountry);
  }

  // Display certain property of an item in field
  // or return search string jumble
  displayOneFn(value: any | null) {
    if (typeof value === 'string') {
      return value;
    } else if (value !== null) {
      return value.code;
    }
  }

  setCountries() {
    this.countries = countries;
    this.selectedCountry = this.formControl.value;
    this.filteredCountries = this.formControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterSingle(value))
    );
    this.filteredCountries.subscribe(data => (this.filteredCountriesArray = data));
  }

  private _filterSingle(value: string): any[] {
    if (typeof value === 'string') {
      value = value.trim();
      if (value !== '') {
        this.lastFilter = value;
      }
      const filterValue = value.toLowerCase();
      return this.countries.filter(country =>
        country.code.toLowerCase().includes(filterValue)
      );
    } else {
      return [];
    }
  }

  get formControl() {
    return this.form.get(this.element.fieldCode)!;
  }

  //emit event to conditionally show/hide fields based on document type selection
  onDocumentTypeChanged(event: any) {
    this.onDocTypeChange.next(event);
  }

  chipClicked(chip: any) {
    this.onChipSelect.next(chip);
  }
  removeChip(chipToRemove: any, chipArray: any): void {
    const index = chipArray.indexOf(chipToRemove);
    if (index >= 0) {
      chipArray.splice(index, 1);
    }
    this.element.value = chipArray;
    this.form.get(chipToRemove.fieldCode)!.setValue(JSON.stringify(this.element.value));
    this.form.controls[chipToRemove.fieldCode].markAsDirty();
    this.onChipRemove.next(chipToRemove);
  }

  addChip(event: any, chipArray: any, elementFieldCode: any): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      //remove all leading colons 
      let chipValue = value.trim().replace(/^:+/, '');
      chipArray.push({
        fieldCode: elementFieldCode, value: chipValue,
        key: elementFieldCode + (chipArray.length + 1)
      });
      this.element.value = chipArray;
      this.form.get(elementFieldCode)!.setValue(JSON.stringify(this.element.value));
      if (input) {
        input.value = '';
      } else {
        if (event.classList?.contains('mat-chip-list-wrapper')) {
          event.querySelector('.mat-input-element').value = '';
        }
        event.value = '';
      }
    }
  }

}