import { Injectable } from '@angular/core';
import {
  GridManager,
  RowAction,
  GridRowActionManager,
  RowActionParams,
  UpdateRowsCommandEvent,
} from '@basware/gt-grid';
import { RowNode } from 'ag-grid-community';
import { GridTaxData } from '../../taxes-and-sums.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';

@Injectable()
export class EditableValidatingGridActionManager extends GridRowActionManager<GridTaxData> {
  private actions: RowAction<GridTaxData>[] = [
    {
      rel: 'delete',
      text: 'Delete',
      tooltip: 'Delete',
      iconConfig: {
        fontSet: 'far',
        icon: 'fa-trash-alt',
      },
      onAction: (params: RowActionParams<GridTaxData>) => {
        if (!params.data) {
          return;
        }

        if (this.checkIfRowContainsData(params.data)) {
          let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '480px',
            data: {
              message: 'spdf.app.module.tax.data.confirmation.dialog.message',
              title: 'spdf.app.module.tax.data.confirmation.dialog.title',
              buttonText: {
                ok: 'spdf.app.module.invoice.lines.confirmation.dialog.confirmButtonText',
                cancel: 'spdf.app.module.invoice.lines.confirmation.dialog.cancelButtonText'
              }
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            if (res && params.data) {
              this.removeRow(params.data)
            }
          })
        } else {
          this.removeRow(params.data)
        }
      },
    },
  ];

  removeRow(data: GridTaxData) {
    this.gridManager.dispatch(
      new UpdateRowsCommandEvent({
        add: [],
        update: [],
        remove: [data],
      })
    );
  }

  constructor(private gridManager: GridManager, private dialog: MatDialog) {
    super();
  }

  checkIfRowContainsData(data: GridTaxData) {
    let rowData = Object.values(data).splice(1).map(x => x.value);
    return rowData.join("").length > 0 ? true : false
  }

  getAllActions(rowNode: RowNode<GridTaxData>): RowAction<GridTaxData>[] {
    return this.actions;
  }
}
